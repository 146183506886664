<template>
    <div id="job-list">
        <v-head currentIndex=6></v-head>
        <div class="job-bannel">
            <div class="search-container">
                <div class="search-box">
                    <a-dropdown :trigger="['click']" @visibleChange="onDropdownChange">
                        <div class="address-filter" :data-areaLevel1-id="areaLevel1Id" :data-areaLevel2-id="areaLevel2Id">{{addressName || "全部地區"}}</div>
                        <a-menu slot="overlay"  @click="handleMenuClick">
                            <a-menu-item>全部</a-menu-item>
                            <a-sub-menu v-for="(data, key) in area2Map" :key="key" :title="key">
                                <a-menu-item @click="()=>onValueItemClick(key,i)" v-for="(area2,i) in area2Map[key]" :key="area2.codeid">{{area2.codedesc}}</a-menu-item>
                            </a-sub-menu>
                        </a-menu>
                    </a-dropdown>
                    <div class="loading-address" v-if="loadingAddress">
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                    <input type="text" class="search-kw" v-model="keyword" placeholder="請輸入名稱" v-on:keyup.enter="doSearch"/>
                    <div class="btn-search" @click="doSearch">搜索職位</div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="empty" v-if="!loading && !dataList.length">-- 已經全部加載 --</div>
           <router-link :to="`/job/details/${item.jobid}`" class="job-item" v-for="(item, index) in dataList" :key="index">
               <div class="row-title">
                    <span class="job-type" v-text="item.employmentType"/>
                    <h1 class="title" v-text="item.title"></h1>
                    <span class="count">({{item.number}}名)</span>
                    <span class="location-icon" v-if="item.location"></span>
                    <span class="location-text" v-text="item.location"></span>
                </div>
                <div class="job-intro">
                    <div class="intro-title">職位有效期</div>
                    <div class="intro-content" v-html="item.expiredate || '--'"></div>
                </div>
                <div class="job-intro">
                    <div class="intro-title">職位責任</div>
                    <div class="intro-content" v-html="item.jobdesc || '--'"></div>
                </div>
                <div class="job-intro">
                    <div class="intro-title">任職資格</div>
                    <div class="intro-content" v-html="item.requirement || '--'"></div>
                </div>
                <div class="clinic-info">
                    <img :src="item.cliniclogo" :alt="item.clinicname" class="logo">
                    <h2 class="name" v-text="item.clinicname"></h2>
                </div>
            </router-link>
            <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
            <loading v-if="loading"/>
        </div>
        <v-foot></v-foot>
    </div>
</template>

<script>
	import vHead from '@/layouts/Head.vue'
	import vFoot from '@/layouts/Foot.vue'
    import job_list from '@/views/mixins/job_list'
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
	export default {
        mixins:[
            job_list,
        ],
        components: {
            vHead,
            vFoot,
            Loading,
            SplitPage
        },
        data: function(){
            return {
                visible: false,
                loading: true,
                pageSize: 10,
                pageNo: 1,
                total: 0,
                dataList: [],
                area1List: [],
                area2Map: {},
                keyword: '',
                addressName: '',
                loadingAddress: false,
                areaLevel1Id: undefined,
                areaLevel2Id: undefined,
                locationMap: {},
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
			this.onRefresh()
        },
        methods: {
            handleMenuClick: function(v) {
                if(v.key == 'item_0') {
                    this.addressName = ''
                    this.arealevel1id = undefined
                    this.areaLevel2Id = undefined
                }
            },
            onValueItemClick: function(key,i) {
                this.addressName = `${key}/${this.area2Map[key][i].codedesc}`
                this.arealevel1id = this.area1List.find((v)=>v.codedesc == key).codeid
                this.areaLevel2Id = this.area2Map[key][i].codeid
            },
            doSearch: function() {
                this.pageNo = 1;
                this.onRefresh()
            },
            onDropdownChange: async function(v)  {
                if(this.loadingAddress) {
                    return
                }
                if(v) {
                    //获取地区
                    this.loadingAddress = true
                    var area1ListJson = sessionStorage.getItem('area1List')
                    var area2MapJson = sessionStorage.getItem('area2Map')
                    if(area1ListJson && area2MapJson) {
                        this.area1List = JSON.parse(area1ListJson)
                        this.area2Map = JSON.parse(area2MapJson)
                    } else {
                        this.area1List = await this.$store.dispatch('getAreaList', {'langcode': 'B5'});
                        const map = {};
                        for(let item of this.area1List) {
                            if(item.codeid == -1) {
                                map[item.codedesc] = []
                                continue
                            }
                            const _data = await this.$store.dispatch('getAreaList', {'langcode': 'B5', 'parentId': item.codeid});
                            map[item.codedesc] = _data;
                        }
                        this.area2Map = {...map};
                        if(this.area1List.length) {
                            sessionStorage.setItem('area1List', JSON.stringify(this.area1List))
                            sessionStorage.getItem('area2Map', JSON.stringify(this.area2Map))
                        }
                    }
                    this.loadingAddress = false
                }
            },
            onPageChanged: function(pageNo){
                var path = this.$route.fullPath
                var hash = this.$route.hash
                if(hash && path.indexOf(hash) != -1) {
                    path = path.replace(hash, '')
                }
                this.$router.push({path:  path+'#page='+pageNo},(e)=>{});
            },
            setAreaName: async function()  {
                var areaList = await this.$store.dispatch('getAreaList', {'langcode': 'B5'})
                if(this.dataList.length) {
                    for(let i=0; i<this.dataList.length; i++) {
                        const data = this.dataList[i]
                        const params = {
                            areaLevel1Id: data.arealevel1id,
                            areaLevel2Id: data.arealevel2id,
                            langcode: 'B5',
                        }
                        const area1 = areaList.find((v)=>v.codeid == data.arealevel1id)
                        var _area2 = this.locationMap[`${data.arealevel1id}-${data.arealevel2id}`]
                        const area2 = _area2 || await this.$store.dispatch('getAreaDetail', params)
                        this.locationMap[`${data.arealevel1id}-${data.arealevel2id}`] = area2
                        data.location = `${area1.codedesc} ${area2.codedesc}`
                    }
                    this.dataList = [...this.dataList]
                }
            },
            onRefresh: async function(){
                if(this.total > 0 && this.pageNo > 0) {
                    var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                    if(maxPage > 0 && maxPage < this.pageNo) {
                        var path = this.$route.fullPath
                        var hash = this.$route.hash
                        if(hash && path.indexOf(hash) != -1) {
                            path = path.replace(hash, '')
                        }
                        this.$router.push({path: `${path}#page=1`},(e)=>{})
                        return
                    }
                }
                this.loading = true
                this.employmentTypeList = await this.$store.dispatch('getEmploymentType', {}) || []
                const params = {
                    pageNum: this.pageNo,
                    pageSize: this.pageSize,
                    areaLevel1Id: this.areaLevel1Id,
                    areaLevel2Id: this.areaLevel2Id,
                }
                if(this.keyword) {
                    params['title'] = this.keyword
                }
                this.loadData(params, (data, error)=>{
                    this.loading = false
                    if(error) {
                        this._toast.warning(this, error)
                    } else {
                        this.dataList = data.list.map((v)=>{
                            var employmentTypeData = v.employmenttypeid && this.employmentTypeList && this.employmentTypeList.find((e)=>e.codeid == v.employmenttypeid)
                            var employmentType = employmentTypeData && employmentTypeData.codeDesc;
                           return {...v, employmentType};
                        });
                        this.total = data.total
                        this.visible = this.total > 0
                        var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                        if(maxPage > 0 && maxPage < this.pageNo) {
                            var path = this.$route.fullPath
                            var hash = this.$route.hash
                            if(hash && path.indexOf(hash) != -1) {
                                path = path.replace(hash, '')
                            }
                            this.$router.push({path: `${path}#page=1`},(e)=>{})
                            return
                        }
                        this.setAreaName();
                    }
                })
            },
        },
        watch: {
            '$route.hash': function(v){
                var hash = this.$route.hash
                hash = hash.replace('#', '')
                var list = hash.split('&')
                var key = list[0].split('=')[0]
                var value = list[0].split('=')[1] / 1
                if(key == 'page') {
                    if(! isNaN(value)) {
                        this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                    }
                }
                this.onRefresh()
            },
        }
    }
</script>

<style lang="scss" scoped>
    #job-list {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .content {
        flex: 1;
        width: 1000px;
        min-height: 500px;
        position: relative;
        margin: 0px auto;
    }

    .content .empty {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 50px;
        z-index: 0;
        color: #9E9E9E;
        font-size: 24px;
    }

    .job-item {
        position: relative;
        display: block;
        padding: 32px 26px;
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 12px;
    }

    .job-item > .row-title {
        display: flex;
        flex-direction: row;
        line-height: 25px;
    }

    .job-item > .row-title .job-type {
        display: inline-block;
        line-height: 24px;
        height: 24px;
        padding: 0px 8px;
        border-radius: 2px;
        margin-right: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        background-color: #2B99A6;
    }

    .job-item > .row-title .title {
        color: #231F20;
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
    }

    .job-item > .row-title .count {
        color: #9E9E9E;
        font-size: 24px;
        line-height: 1.0;
        margin-left: 12px;
        margin-right: 35px;
    }

    .job-item > .row-title .location-icon {
        display: inline-block;
        width: 14px;
        height: 16px;
        margin-top: 5px;
        margin-right: 6px;
        background-image: url(../../../assets/imgs/pc/jobs/icon_dingwei@2x.png);
        background-size: 14px 16px;
        vertical-align: bottom;
    }
    .job-item > .row-title .location-text {
        color: #2B99A6;
        font-size: 16px;
        line-height: 16px;
        margin-top: 5px;
    }

    .job-item .job-intro {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
    }

    .job-item .job-intro .intro-title {
        color: #231F20;
        font-size: 14px;
        line-height: 14px;
    }

    .job-item .job-intro .intro-content {
        color: #969696;
        font-size: 14px;
        margin-top: 8px;
    }

    .job-item .job-intro:first-child {
        margin-bottom: 35px;
    }

    #job-list .job-bannel > .search-container {
        width: 1000px;
        margin: 20px auto;
        height: 138px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/news/img_bannerbg@2x.png);
        position: relative;
    }

    #job-list .job-bannel > .search-container .search-box {
        height: 36px;
        line-height: 36px;
        width: 549px;
        position: relative;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        border-radius: 8px;
        position: absolute;
        left: 226px;
        top: 36px;
    }

    .job-bannel .search-container > .search-box > .address-filter  {
        padding-left: 17px;
        padding-right: 31px;
        position: relative;
        border: 1px solid #2B99A6;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        cursor: pointer;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
    }

    .job-bannel .search-container > .search-box > .address-filter::after {
        content: '';
        position: absolute;
        right: 11px;
        top: 50%;
        width: 10px;
        height: 7px;
        transform: translateY(-50%);
        background-image: url(../../../assets/imgs/pc/img_xial2@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    #job-list .job-bannel > .search-container .search-box > .search-kw {
        flex: 1;
        padding-left: 9px;
        line-height: 36px;
        border-top: 1px solid #2B99A6;
        border-bottom: 1px solid #2B99A6;
        box-sizing: border-box;
        font-weight: bold;
        color: #231F20;
        font-size: 16px;
    }
    #job-list .job-bannel > .search-container .search-box > .btn-search {
        width: 78px;
        line-height: 36px;
        background-color: #36C4D0;
        color: #fff;
        font-size: 16px;
        height: 36px;
        text-align: center;
        font-weight: bold;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid #36C4D0;
        box-sizing: border-box;
        cursor: pointer;
    }

    #job-list .job-bannel > .search-container .loading-address{
        position: absolute;
        left: 0;
        top: 0;
        width: 82px;
        padding-top: 2px;
        text-align: center;
    }

    #job-list .clinic-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
    }

    #job-list .clinic-info > img {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 4px;
        object-fit: contain;
        border: 1px solid #f3f3f3;
    }

    #job-list .clinic-info > img[src=""] {
        position: relative;
    }

    #job-list .clinic-info > img[src=""]::after {
        content: '';
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f1f1f1;
        position: absolute;
        left: 0;
        top: 0;
    }

    #job-list .clinic-info > .name {
        color: #666;
        font-size: 14px;
        font-weight: bold;
        margin-left: 8px;
    }

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #36C4D0;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #36C4D0 transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
